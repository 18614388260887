<template>
    <div class="PlanEdit" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never">
            <el-page-header @back="goBack" />
        </el-card>

        <el-card shadow="never" style="margin-top: 8px">
            <el-form @submit.native.prevent :inline="true" size="small" label-width="80px">
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="促销机构">
                            <el-input readonly v-model="detail.deptName" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="促销类型">
                            <el-input readonly v-model="detail.showRule" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="方案名称">
                            <el-input readonly v-model="detail.name" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="创建人">
                            <el-input readonly v-model="detail.creator" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="创建日期">
                            <el-date-picker
                                v-model="detail.createTime"
                                value-format="yyyy-MM-dd"
                                readonly
                                style="width: 200px"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="开始日期">
                            <el-date-picker
                                type="date"
                                v-model="detail.startDate"
                                value-format="yyyy-MM-dd"
                                placeholder="选择日期"
                                readonly
                                style="width: 200px"
                            />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="结束日期">
                            <el-date-picker
                                v-model="detail.endDate"
                                value-format="yyyy-MM-dd"
                                type="date"
                                placeholder="选择日期"
                                readonly
                                style="width: 200px"
                            />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="促销日">
                            <el-checkbox-group v-model="detail.showWeekdays" onclick="return false">
                                <el-checkbox :label="'1'">周一</el-checkbox>
                                <el-checkbox :label="'2'">周二</el-checkbox>
                                <el-checkbox :label="'3'">周三</el-checkbox>
                                <el-checkbox :label="'4'">周四</el-checkbox>
                                <el-checkbox :label="'5'">周五</el-checkbox>
                                <el-checkbox :label="'6'">周六</el-checkbox>
                                <el-checkbox :label="'7'">周日</el-checkbox>
                            </el-checkbox-group>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="作用群体">
                            <el-checkbox-group v-model="detail.checkList" onclick="return false">
                                <el-checkbox :label="1">会员</el-checkbox>
                                <el-checkbox :label="2">非会员</el-checkbox>
                            </el-checkbox-group>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-button type="primary" @click="handleAudit" size="small">审核详情</el-button>
            <PickupReturnAudit ref="PickupReturnAudit" :reviewPrivilegeFlag="'menu.promotion.plan.review'" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px" align="left">
            <el-table
                id="printMe"
                border
                stripe
                style="width: 100%"
                :data="tableData"
                size="mini"
                :highlight-current-row="true"
                max-height="500"
            >
                <el-table-column label="序号" type="index" width="60" align="center" fixed="left" />
                <el-table-column prop="sku.name" label="商品名称" width="180" />
                <el-table-column label="条码" width="180">
                    <template slot-scope="scope">
                        <span v-for="b in scope.row.sku.bars" :key="b">{{ b }}<br /></span>
                    </template>
                </el-table-column>
                <el-table-column prop="sku.specs" label="规格" width="130" />
                <el-table-column prop="sku.category" label="类目" width="100" />
                <el-table-column prop="sku.unit" label="单位" width="80" />
                <el-table-column prop="sku.brand" label="品牌" width="180" />
                <el-table-column prop="retailPrice" label="当前零售价" width="100">
                    <template slot-scope="scope">
                        <span>{{ scope.row.retailPrice | money }}</span>
                    </template>
                </el-table-column>
                <template v-if="detail.ruleType == 1">
                    <el-table-column prop="discounts" label="折扣率" width="80" />
                    <el-table-column prop="discounts" label="折后价" width="100">
                        <template slot-scope="scope">
                            <span>{{ scope.row.discounts | discountMoney(scope.row.retailPrice) }}</span>
                        </template>
                    </el-table-column>
                </template>
                <template v-if="detail.ruleType == 2">
                    <el-table-column prop="discounts" label="偶数折扣率" width="100" />
                    <el-table-column prop="discounts" label="偶数折后价" width="100">
                        <template slot-scope="scope">
                            <span>{{ scope.row.discounts | discountMoney(scope.row.retailPrice) }}</span>
                        </template>
                    </el-table-column>
                </template>
                <template v-if="detail.ruleType == 3">
                    <el-table-column prop="favourPrices" label="特惠价" width="100">
                        <template slot-scope="scope">
                            <span>{{ scope.row.favourPrices | money }}</span>
                        </template>
                    </el-table-column>
                </template>
            </el-table>
        </el-card>
    </div>
</template>

<script>
import UrlUtils from 'js/UrlUtils';
import PickupReturnAudit from 'components/PickupReturnAudit';
import MoneyUtils from 'js/MoneyUtils';
import Decimal from 'decimal';
import qs from 'qs';

export default {
    name: 'PlanDetail',
    components: { PickupReturnAudit },
    props: ['form'],
    data() {
        return {
            baseUrl: '/promotion',
            detail: {
                deptCode: '',
                ruleCode: '',
                memberScopeFlag: '',
                name: '',
                startTime: '',
                endTime: '',
                showWeekdays: [],
                showRule: '',
                checkList: [],
                planDateArr: [],
            },
            meta: {
                types: [],
            },
            tableData: [],
            url: {
                goods: '/goods/goods/list_c',
            },
        };
    },
    mounted() {
        this.inits();
    },
    methods: {
        handleAudit() {
            this.$refs.PickupReturnAudit.show(this.detail.code, this.baseUrl);
        },
        inits() {
            const _this = this;
            Object.assign(this.detail, this.form);
            if (_this.form.weekdays.indexOf(',') >= 0) {
                _this.detail.showWeekdays = _this.form.weekdays.split(',');
            } else {
                _this.detail.showWeekdays.push(_this.form.weekdays);
            }
            _this.detail.showRule = _this.ruleType(_this.form.ruleType);
            if (_this.form.memberScopeFlag == 2) {
                _this.detail.checkList = [1, 2];
            } else if (_this.form.memberScopeFlag == 1) {
                _this.detail.checkList = [1];
            } else if (_this.form.memberScopeFlag == 0) {
                _this.detail.checkList = [2];
            }
            this.handleQueryGoods();
        },
        handleQueryGoods() {
            const _this = this;
            UrlUtils.QueryRemote(this, '/promotion/info_c/' + this.form.code, (rst) => {
                UrlUtils.QueryRemote(
                    _this,
                    this.url.goods + '?' + qs.stringify({ codes: rst.goodsCodes }, { indices: false }),
                    (data) => {
                        _this.tableData = data;
                        _this.tableData.forEach((v, i) => {
                            v.discounts = rst.discounts[i];
                            v.codes = rst.codes[i];
                            v.favourPrices = rst.favourPrices[i];
                        });
                    }
                );
            });
        },
        ruleType(type) {
            switch (type) {
                case 1: {
                    return '单品折扣';
                }
                case 2: {
                    return '单品偶数折扣';
                }
                case 3: {
                    return '单品特价';
                }
            }
        },
    },
    filters: {
        discountMoney(discount, prices) {
            const temDiscount = Decimal(discount).div(10);
            const temPrices = MoneyUtils.moneyToYuan(prices);
            return Decimal(temDiscount).mul(Decimal(temPrices)).toNumber().toFixed(2);
        },
    },
};
</script>

<style scoped>
.BatchCreate .el-form-item {
    margin-bottom: 0;
}

.BatchCreate .el-table th.gutter {
    display: table-cell;
}
</style>
